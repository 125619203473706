import { Box, Container } from "@chakra-ui/layout";
import { domToReact } from "html-react-parser";
import options from "../../support/parse-settings";
import { css } from "@emotion/react";

const GutenbergGroup = ({ attribs, children, styles }) => {
  return (
    <Box
      className={attribs?.class}
      css={css`
        ${styles}
      `}
    >
      <Container
        sx={{
          ".chakra-container": {
            px: 0,
          },
        }}
      >
        {domToReact(children, options)}
      </Container>
    </Box>
  );
};

export default GutenbergGroup;
